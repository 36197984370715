import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';
import Meta from '../meta';
import SEO from '../seo';
import BlogPostPrevNext from '../blog-post-prev-next';
import { Disqus } from 'gatsby-plugin-disqus';

export default function BlogPost({ data, pageContext }) {
    const post = data.markdownRemark;
    const title = post.frontmatter.title;
    const description = post.frontmatter.description;

    // Used for disqus
    const siteUrl = data.site.siteMetadata.siteUrl;
    let disqusConfig = {
        url: `${siteUrl + pageContext.slug}`,
        identifier: post.id,
        title,
    };

    return (
        <Layout>
            <SEO
                title={title}
                description={description}
                slug={pageContext.slug}
            />
            <article>
                <header>
                    <h1 className="header text-4xl md:text-5xl">{title}</h1>
                    <div className="pb-2 pt-1 mt-8 mb-10 border-b border-t border-dashed border-gray-700">
                        <p className="text-lg md:text-xl mb-2 italic">
                            {description}
                        </p>
                        <Meta
                            date={post.frontmatter.date}
                            datePretty={post.frontmatter.datePretty}
                            lastmod={post.frontmatter.lastmod}
                            lastmodPretty={post.frontmatter.lastmodPretty}
                            tags={post.frontmatter.tags}
                            readingTime={post.fields.readingTime.text}
                        />
                    </div>
                </header>
                <section
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                />
            </article>
            <BlogPostPrevNext pageContext={pageContext} />
            <h2 className="header text-3xl">Comments:</h2>
            <Disqus config={disqusConfig} />
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            html
            frontmatter {
                title
                date(formatString: "YYYY-MM-DD")
                datePretty: date(formatString: "MMMM Do, YYYY")
                lastmod(formatString: "YYYY-MM-DD")
                lastmodPretty: lastmod(formatString: "MMMM Do, YYYY")
                description
                tags
            }
            fields {
                readingTime {
                    text
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;
