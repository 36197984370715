import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const BlogPostPrevNext = ({ pageContext }) => {
    const prev = pageContext.prev
        ? {
              url: `${pageContext.prev.fields.slug}`,
              title: pageContext.prev.frontmatter.title,
          }
        : null;

    const next = pageContext.next
        ? {
              url: `${pageContext.next.fields.slug}`,
              title: pageContext.next.frontmatter.title,
          }
        : null;

    return (
        <nav className="text-2xs overflow-hidden my-16 pagination-border">
            {prev && (
                <div className="text-left float-left pr-4 w-1/2 md:w-1/3">
                    <span className="arrow-previous">Previous Post</span>{' '}
                    <Link className="block" to={prev.url}>
                        {prev.title}
                    </Link>
                </div>
            )}
            {next && (
                <div className="text-right float-right pl-4 w-1/2 md:w-1/3">
                    <span className="arrow-next">Next Post</span>{' '}
                    <Link className="block" to={next.url}>
                        {next.title}
                    </Link>
                </div>
            )}
        </nav>
    );
};

BlogPostPrevNext.propTypes = {
    date: PropTypes.object,
};

BlogPostPrevNext.defaultProps = {
    pageContext: {},
};

export default BlogPostPrevNext;
